import { useEffect, useState } from "react";
import "./App.css";
import { useHistory, useLocation } from "react-router-dom";
import API from "./Api";
import { ResponseOptions, TextInput } from "./Types";

/**
 * represents the single page application for airfy support link
 */
function App() {
  let history = useHistory();
  const [response, setResponse] = useState<ResponseOptions>();
  const [page, setPage] = useState<string | null>();
  const [textInputs, setTextInputs] = useState<TextInput[]>([]);

  /**
   * Function to get a parameter from the current URL
   * @param p paramter the method should look for
   */
  function GetURLParam(p) {
    const query = new URLSearchParams(useLocation().search);
    let param = query.get(p);
    return param;
  }

  //initial Paramter p
  let i = GetURLParam("p");

  //session_id paramter s
  let j = GetURLParam("s");

  /**
   * Function that creates post request on /submit with the corresponding payload
   * payload takes session_id, current page and input array {current page : button name}
   * Function is designed for every Button thta results from option array in response
   * @param buttonName Name of the button that was pressed
   */
  function handleButton(buttonName: string) {
    const payload = {
      session_id: j,
      page: page,
      inputs: {
        currentPage: buttonName,
      },
    };

    const payloadJSON = JSON.stringify(payload);
    const parsedPayloadJSON = JSON.parse(
      payloadJSON.split('"currentPage":').join(`"${page}":`)
    );
    API.post("/submit", parsedPayloadJSON)
      .then(function (response) {
        setPage(response.data.next_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  /**
   * function to register every change of input in the corresponding input fields, get triggered onChange in input
   * saves the changes in variable textInputs
   * checks the key if it is already in array,
   * if existent it overrrides the value for that key
   * if not existent adds the key-value pair
   * @param evt event onChange on input fields
   */
  function handleTextInput(evt) {
    const value = evt.target.value;
    const key = evt.target.name;
    const input = { key, value };
    let keyExist = 0;

    if (textInputs.length > 0) {
      textInputs.forEach((element) => {
        if (element.key === key) {
          keyExist = 1;
        } else {
          keyExist = 0;
        }
      });
      if (keyExist === 1) {
        textInputs.forEach((element) => {
          if (element.key === key) {
            element.value = value;
          }
        });
      } else {
        setTextInputs([...textInputs, input as TextInput]);
      }
    } else {
      setTextInputs([{ key: key, value: value }]);
    }
  }

  /**
   * function to submit textInputs
   * itterates textInputs and takes key+value to create the inputs array for the payload
   */
  function submitForm(buttonName: string) {
    let inputs: string[] = [];
    inputs.push(page + ":" + buttonName);
    for (let i of textInputs) {
      const string = i.key + ":" + i.value;
      inputs.push(string);
    }

    const payload = {
      session_id: j,
      page: page,
      inputs,
    };

    const json = JSON.stringify(payload);

    API.post("/submit", json)
      .then(function (response) {
        setPage(response.data.next_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //being rerendered when ever the page variable is being updated
  useEffect(() => {
    /**
     * function to get the session_id
     * takes the initial parameter
     */
    function getSessionID() {
      API.post("/create_session?init_params=" + i)
        .then(function (response) {
          console.log(response);
          setPage(response.data.start_page);
          let session_id = response.data.session_id;
          history.push("?s=" + session_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    /**
     * function to get a page
     * takes the page variable from any response
     */
    function getPage() {
      API.get("/pages/" + page)
        .then(function (response) {
          console.log(response);
          const responseOptions: ResponseOptions = response.data as ResponseOptions;
          setResponse(responseOptions);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (i !== null || j !== null) {
      if (page === undefined) {
        getSessionID();
      }
      if (page !== undefined) {
        getPage();
      }
    }
  }, [page]);

  return (
    <body className="page-welcome">
      <div className="box">
        <div className="logo"></div>
        <div className="content tx-center">
          <h1>{response && response.title}</h1>
          <p className="mt36">{response && response.text}</p>
          {response &&
            response.options.length === 1 &&
            response.inputs.length === 0 &&
            response.options.map((option, index) => (
              <button
                key={index}
                className="btn"
                onClick={() => handleButton(option.name)}
              >
                {option.text}
              </button>
            ))}
          <div className="form-wrap">
            {response &&
              response.options.length > 1 &&
              response.options.map((option, index) => (
                <label className="checkbox" key={index}>
                  <input
                    type="radio"
                    name="radio-1"
                    onClick={() => handleButton(option.name)}
                  ></input>
                  <span className="checkbox-input"></span>
                  <span className="checkbox-text">{option.text}</span>
                </label>
              ))}

            {response && response.inputs.length > 0 && (
              <form className="bg tx-center">
                {response &&
                  response.inputs.map((input, index) => (
                    <input
                      key={index}
                      placeholder={input.placeholder}
                      name={input.name}
                      onChange={handleTextInput}
                    ></input>
                  ))}
              </form>
            )}
            {response &&
              response.options.length === 1 &&
              response.inputs.length > 0 &&
              response.options.map((option, index) => (
                <button
                  key={index}
                  className="btn"
                  onClick={() => submitForm(option.name)}
                >
                  {option.text}
                </button>
              ))}
          </div>
        </div>
      </div>
      <script src="js/main.js"></script>
      <svg className="svg-icon">
        <symbol id="ic-icon" viewBox="0 0 0 0"></symbol>
      </svg>
    </body>
  );
}

export default App;
